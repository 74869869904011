//==============================
// Typography
//==============================

h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd {
  margin: 0;
  padding: 0;
}

@mixin h1 {
  font: 700 normal 85px/1 $font-secondary;
  color: $red;
  letter-spacing: -.02em;

  @include bp(l) {
    font-size: 70px;
  }

  @include bp(m) {
    font-size: 60px;
  }

  @include bp(s) {
    font-size: 44px;
  }
}

.h1 {
  @include h1;

  + p {
    margin-top: .8em;
  }

  span {
    font-size: 90%;
    color: #bbb;
  }
}

@mixin h2 {
  font: 700 normal 70px/1 $font-secondary;
  color: red;
  letter-spacing: -.02em;

  @include bp(l) {
    font-size: 60px;
  }

  @include bp(m) {
    font-size: 40px;
  }

  @include bp(s) {
    font-size: 34px;
  }
}

.h2 {
  @include h2;
  border-top: 0;
  padding-top: 0;

  + p {
    margin-top: .8em;
  }
}

@mixin h3 {
  font: 400 normal 30px/1.4 $font-primary;
  color: $black;
  letter-spacing: -.02em;

  @include bp(l) {
    font-size: 28px;
  }

  @include bp(m) {
    font-size: 26px;
  }

  @include bp(s) {
    font-size: 24px;
  }
}

.h3 {
  @include h3;

  + p {
    margin-top: .8em;
  }
}


@mixin h4 {
  font: 700 normal 22px/1.4 $font-primary;
  color: $black;
  letter-spacing: -.02em;

  @include bp(l) {
    font-size: 21px;
  }

  @include bp(m) {
    font-size: 20px;
  }

  @include bp(s) {
    font-size: 18px;
  }
}

.h4 {
  @include h4;

  + p {
    margin-top: .8em;
  }
}

@mixin h5 {
  font: 700 normal 18px/1.4 $font-primary;
  color: $black;
  letter-spacing: .02em;
  text-transform: uppercase;

  @include bp(l) {
    font-size: 17px;
  }

  @include bp(m) {
    font-size: 16px;
  }
}

.h5 {
  @include h5;

  + p {
    margin-top: .8em;
  }
}

@mixin p {
  font: 400 normal 18px/1.8 $font-primary;
  color: $black;

  @include bp(l) {
    font-size: 17px;
  }

  @include bp(m) {
    font-size: 16px;
  }
}

p {
  @include p;
}

@mixin eyebrow {
  font: 800 normal 18px/1.3 $font-primary;
  text-transform: uppercase;
  color: $black;
  letter-spacing: .03em;
  margin-bottom: 0.5em;

  @include bp(m) {
    font-size: 14px;
  }
}

.eyebrow {
  @include eyebrow;
}
