/*!
Theme Name: Punk Rock Time Machine
Author: Robert Pessagno
Version: 1.0
Template: storefront
Description: This is a custom child theme for WooThemes StoreFront theme
*/
@font-face {
  font-family: "Straight Jacket";
  src: url("assets/fonts/straightjacketbb_reg.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Straight Jacket";
  src: url("assets/fonts/straightjacketbb_bold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Badaboom";
  src: url("assets/fonts/badaboom.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Born Strong";
  src: url("assets/fonts/Born Strong Regular.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Born Strong";
  src: url("assets/fonts/Born Strong Black.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-ExtraBoldItalic.ttf");
  font-weight: 800;
  font-style: italic;
}
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  box-sizing: border-box;
}

body,
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

[tabindex="0"] {
  outline: 0;
}

.main {
  min-height: 800px;
}
.main:after {
  content: "";
  display: table;
  clear: both;
}

img {
  border-radius: 0;
}

h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd {
  margin: 0;
  padding: 0;
}

.h1 {
  font: 700 normal 85px/1 "Born Strong", Helvetica, Arial, sans-serif;
  color: #ff0000;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 1200px) {
  .h1 {
    font-size: 70px;
  }
}
@media screen and (max-width: 1023px) {
  .h1 {
    font-size: 60px;
  }
}
@media screen and (max-width: 767px) {
  .h1 {
    font-size: 44px;
  }
}
.h1 + p {
  margin-top: 0.8em;
}
.h1 span {
  font-size: 90%;
  color: #bbb;
}

.h2 {
  font: 700 normal 70px/1 "Born Strong", Helvetica, Arial, sans-serif;
  color: red;
  letter-spacing: -0.02em;
  border-top: 0;
  padding-top: 0;
}
@media screen and (max-width: 1200px) {
  .h2 {
    font-size: 60px;
  }
}
@media screen and (max-width: 1023px) {
  .h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 767px) {
  .h2 {
    font-size: 34px;
  }
}
.h2 + p {
  margin-top: 0.8em;
}

.h3 {
  font: 400 normal 30px/1.4 "Montserrat", Helvetica, Arial, sans-serif;
  color: #000;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 1200px) {
  .h3 {
    font-size: 28px;
  }
}
@media screen and (max-width: 1023px) {
  .h3 {
    font-size: 26px;
  }
}
@media screen and (max-width: 767px) {
  .h3 {
    font-size: 24px;
  }
}
.h3 + p {
  margin-top: 0.8em;
}

.h4 {
  font: 700 normal 22px/1.4 "Montserrat", Helvetica, Arial, sans-serif;
  color: #000;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 1200px) {
  .h4 {
    font-size: 21px;
  }
}
@media screen and (max-width: 1023px) {
  .h4 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .h4 {
    font-size: 18px;
  }
}
.h4 + p {
  margin-top: 0.8em;
}

.h5 {
  font: 700 normal 18px/1.4 "Montserrat", Helvetica, Arial, sans-serif;
  color: #000;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
@media screen and (max-width: 1200px) {
  .h5 {
    font-size: 17px;
  }
}
@media screen and (max-width: 1023px) {
  .h5 {
    font-size: 16px;
  }
}
.h5 + p {
  margin-top: 0.8em;
}

p {
  font: 400 normal 18px/1.8 "Montserrat", Helvetica, Arial, sans-serif;
  color: #000;
}
@media screen and (max-width: 1200px) {
  p {
    font-size: 17px;
  }
}
@media screen and (max-width: 1023px) {
  p {
    font-size: 16px;
  }
}

.eyebrow {
  font: 800 normal 18px/1.3 "Montserrat", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.03em;
  margin-bottom: 0.5em;
}
@media screen and (max-width: 1023px) {
  .eyebrow {
    font-size: 14px;
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

select::-ms-expand {
  display: none;
}

.button {
  display: inline-block;
  padding: 0.8em 2em;
  font: 700 normal 20px/1.2 "Born Strong", Helvetica, Arial, sans-serif;
  border: 0;
  transition: background-color 0.3s, color 0.3s;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 1.5em;
  background-color: #ff0000;
  color: #fff;
  border-radius: 5px;
}
@media screen and (max-width: 767px) {
  .button {
    font-size: 16px;
  }
}
.button:hover {
  background-color: #000;
  color: #fff;
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  width: calc(100% - 40px);
  left: 20px;
  top: 20px;
  border-radius: 5px;
  z-index: 10;
  height: 90px;
  padding: 0 30px;
  background-color: #ff0000;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  transition: width 0.4s, background-color 0.4s, transform 0.4s, box-shadow 0.4s;
}
@media screen and (max-width: 1023px) {
  .header {
    height: 70px;
    padding: 0 20px 0 15px;
    width: calc(100% - 20px);
    left: 10px;
    top: 20px;
  }
}
.header.open .header-logo .aaron-logo-white {
  fill: #000;
}
.header.open #menu-main-1 .menu-item {
  color: #000;
}
.header.open #menu-main-1 .menu-item:after {
  border-color: #000;
}
.header.open .header-shop-item {
  color: #000;
}
.header.open .header-shop-item svg {
  fill: #ff0000;
}
.header-logo {
  position: relative;
  z-index: 101;
}
.header-logo img {
  display: block;
  height: 125px;
  width: auto;
}
@media screen and (max-width: 1023px) {
  .header-logo img {
    height: 85px;
  }
}
.header-logo img .aaron-logo-white {
  transition: fill 0.4s;
}
.header-nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .header-nav {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 500px;
    height: 100%;
    z-index: 100;
    margin-left: 0;
    padding: 60px 0 0 0;
    background-color: #ff0000;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    transform: translateX(100%);
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s, transform 0.5s;
  }
}
@media screen and (max-width: 767px) {
  .header-nav {
    max-width: none;
  }
}
@media screen and (max-width: 1200px) {
  .header-nav.open {
    visibility: visible;
    transform: translate(0);
  }
}
.header-nav-logo {
  margin-right: auto;
}
.header-nav-logo a {
  display: block;
}
.header-nav-logo a img {
  width: 100px;
}
.header-nav-menu {
  height: 100%;
  margin-left: auto;
  display: flex;
  flex-flow: row nowrap;
}
@media screen and (max-width: 1200px) {
  .header-nav-menu {
    flex-flow: column nowrap;
    align-items: center;
    height: auto;
    width: 100%;
    height: auto;
    margin-top: auto;
    padding-bottom: 40px;
  }
}
.header-nav-menu li {
  font: 700 normal 20px/1 "Born Strong", Helvetica, Arial, sans-serif;
  list-style-type: none;
  position: relative;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .header-nav-menu li {
    font-size: 18px;
  }
}
.header-nav-menu li a {
  height: 100%;
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  text-decoration: none;
  color: inherit;
  transition: color 0.2s;
  position: relative;
  z-index: 2;
  transition: text-shadow 0.2s;
}
@media screen and (max-width: 1200px) {
  .header-nav-menu li a {
    padding: 0.4em;
    font-size: 30px;
  }
}
.header-nav-menu li a:hover, .header-nav-menu li a[aria-current=page] {
  text-shadow: 2px 2px 0 #660000;
}
.header-nav-utility {
  display: flex;
  flex-flow: row nowrap;
  margin-left: 30px;
}
@media screen and (max-width: 1023px) {
  .header-nav-utility {
    margin-left: 0;
  }
}
.header-nav-utility li {
  list-style-type: none;
}
.header-nav-utility li a {
  display: block;
  padding: 10px;
  transition: opacity 0.2s;
}
.header-nav-utility li a:hover {
  opacity: 0.7;
}
.header-nav-utility li svg {
  display: block;
  height: 25px;
  width: auto;
  fill: #fff;
}
.header-nav-utility + .header-nav-utility {
  border-left: 1px solid #fff;
  margin-left: 20px;
  padding-left: 20px;
}
.header-nav-button {
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  width: 50px;
  height: 50px;
  padding: 0;
  position: relative;
  background-color: transparent !important;
  display: none;
  margin-left: auto;
  order: 1;
  z-index: 101;
  margin-left: auto;
}
@media screen and (max-width: 1200px) {
  .header-nav-button {
    display: block;
  }
}
.header-nav-button span {
  display: block;
  width: 30px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 1;
  transition: transform 0.4s, opacity 0.4s, width 0.4s, background-color 0.4s;
}
.header-nav-button span.top {
  transform: translate(-50%, -7px) rotate(0deg);
  transform-origin: left top;
}
.header-nav-button span.middle {
  transform: translate(-50%, 0) rotate(0deg);
}
.header-nav-button span.bottom {
  transform-origin: left bottom;
  transform: translate(-50%, 7px) rotate(0deg);
}
.header-nav-button.open span.top {
  transform: translate(-35%, -8px) rotate(45deg);
}
.header-nav-button.open span.middle {
  opacity: 0;
}
.header-nav-button.open span.bottom {
  transform: translate(-35%, 12px) rotate(-45deg);
}

.footer {
  background-color: #f0f0f0;
  padding: 50px 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}
.footer-logo {
  width: 200px;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .footer-logo {
    width: 150px;
  }
}
.footer-sauce {
  text-align: center;
  margin-top: 40px;
}
.footer-sauce a {
  color: currentColor;
  text-decoration: none;
}
.footer-sauce a:hover, .footer-sauce a:focus {
  border-bottom: currentColor solid 1px;
}
.footer .header-nav-utility {
  margin-left: 0;
}
.footer .header-nav-utility svg {
  fill: #ff0000;
}

.hero {
  height: 100vh;
  min-height: 800px;
  background: #ccc;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .hero {
    min-height: 600px;
    height: 70vw;
  }
}
@media screen and (max-width: 1023px) {
  .hero {
    min-height: 500px;
  }
}
@media screen and (max-width: 767px) {
  .hero {
    min-height: 400px;
    height: 100vw;
  }
}
.hero img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.social {
  display: flex;
  flex-flow: row nowrap;
}
.social li {
  list-style-type: none;
}
.social li + li {
  margin-left: 10px;
}
.social li a {
  display: block;
  padding: 5px;
  transition: opacity 0.2s;
}
.social li a:hover {
  opacity: 0.7;
}
.social li img {
  display: block;
  height: 40px;
  width: auto;
}
.home-section {
  padding: 140px 0;
}
@media screen and (max-width: 1023px) {
  .home-section {
    padding: 100px 0;
  }
}
@media screen and (max-width: 767px) {
  .home-section {
    padding: 60px 0;
  }
}
.home-section-header {
  text-align: center;
  padding: 0 30px 40px;
}
@media screen and (max-width: 767px) {
  .home-section-header {
    padding-bottom: 20px;
  }
}
.home-split {
  width: calc(100% - 80px);
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1023px) {
  .home-split {
    width: calc(100% - 60px);
  }
}
.home-split-content {
  padding: 40px 100px;
  width: 60%;
}
@media screen and (max-width: 1200px) {
  .home-split-content {
    padding: 60px;
  }
}
@media screen and (max-width: 1023px) {
  .home-split-content {
    padding: 40px;
    width: 100%;
    max-width: 600px;
  }
}
@media screen and (max-width: 767px) {
  .home-split-content {
    padding: 40px 0 0;
  }
}
.home-split-content iframe {
  margin-top: 20px;
}
.home-split-img {
  width: 40%;
}
@media screen and (max-width: 1023px) {
  .home-split-img {
    width: 100%;
    max-width: 600px;
    order: -1;
  }
}
.home-split-img img {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}
.home-video {
  background: #ff0000 url("assets/images/home/video-bg.svg") center center/100% 100% no-repeat;
}
.home-video .eyebrow, .home-video .h1 {
  color: #fff;
}
.home-video-inner {
  width: calc(100% - 120px);
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 767px) {
  .home-video-inner {
    width: calc(100% - 60px);
  }
}
.home-video-smashfuck {
  background: #493c06 url("assets/images/home/smashfuck-video-bg.svg") center center/100% 100% no-repeat;
}
.home-video-warning {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("assets/videos/smashfuck-poster.jpg") center center/cover no-repeat;
  z-index: 1;
}
.home-video-warning-inner {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .home-video-warning-inner {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
}
.home-video-warning-inner .button {
  margin-top: 1em;
}
.home-video.home-hero-video {
  background: #1b539e url("assets/images/home/hero-video-bg.svg") center center/100% 100% no-repeat;
}
.home-music .social {
  margin-top: 10px;
}
.home-music-embed {
  width: 80%;
  margin-top: 100px;
}
@media screen and (max-width: 1023px) {
  .home-music-embed {
    width: 100%;
    max-width: 600px;
    margin-top: 40px;
  }
}
.home-band {
  background: #1ba9e3 url("assets/images/home/band-bg.svg") center center/100% 100% no-repeat;
}
.home-band .h1 {
  color: #fff;
}
.home-band-items {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% - 80px);
  max-width: 1400px;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .home-band-items {
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .home-band-items {
    width: calc(100% - 60px);
  }
}
@media screen and (max-width: 767px) {
  .home-band-items {
    width: calc(100% - 40px);
    max-width: 500px;
  }
}
.home-band-item {
  display: flex !important;
  flex-flow: row wrap;
  align-items: center;
}
.home-band-item:nth-child(5n) {
  margin-right: 0;
}
.home-band-item-img {
  width: 45%;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .home-band-item-img {
    width: 100%;
    height: 100vw;
    max-height: 500px;
  }
}
.home-band-item-img img {
  display: block;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .home-band-item-img img {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
}
.home-band-item-content {
  width: 55%;
  padding: 100px;
}
@media screen and (max-width: 1200px) {
  .home-band-item-content {
    padding: 60px;
  }
}
@media screen and (max-width: 1023px) {
  .home-band-item-content {
    padding: 40px;
  }
}
@media screen and (max-width: 767px) {
  .home-band-item-content {
    padding: 30px;
  }
}
@media screen and (max-width: 767px) {
  .home-band-item-content {
    width: 100%;
  }
}
.home-band .slick-arrow {
  overflow: hidden;
  text-indent: -9999px;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  position: relative;
  width: 80px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
@media screen and (max-width: 1023px) {
  .home-band .slick-arrow {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
    pointer-events: none !important;
  }
}
.home-band .slick-arrow:before {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 40px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
}
@media screen and (max-width: 767px) {
  .home-band .slick-arrow:before {
    width: 30px;
    height: 30px;
  }
}
.home-band .slick-prev {
  order: 1;
}
.home-band .slick-prev:before {
  background: #fff url("assets/images/global/arrow-left.svg") 45% center/30% auto no-repeat;
}
.home-band .slick-next {
  order: 3;
}
.home-band .slick-next:before {
  background: #fff url("assets/images/global/arrow-right.svg") 55% center/30% auto no-repeat;
}
.home-band .slick-dots {
  order: 2;
}
.home-band .slick-list {
  order: 2;
  width: calc(100% - 160px);
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 1023px) {
  .home-band .slick-list {
    width: 100%;
  }
}
.home-band .slick-dots {
  width: 100%;
  margin: 20px 0 0 0;
  padding: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  z-index: 1;
  order: 4;
}
.home-band .slick-dots li {
  width: 7px;
  height: 7px;
  background: none;
  list-style-type: none;
  margin: 0 3px;
  border-radius: 5px;
  background-color: #fff;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.2s;
}
.home-band .slick-dots li.slick-active {
  opacity: 1;
}
.home-band .slick-dots li button {
  display: none;
}
.home-shop .products {
  width: calc(100% - 30px);
  max-width: 1600px;
  margin: 0 auto;
}

.woocommerce-page .post-content {
  max-width: 1200px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}
.woocommerce-page .post-content:after {
  content: "";
  display: table;
  clear: both;
}
.woocommerce-page .woocommerce-tabs,
.woocommerce-page .products.related,
.woocommerce-page .product_meta {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none !important;
}
.woocommerce-page .entry-header,
.woocommerce-page .woocommerce-products-header {
  padding-bottom: 40px;
}
.woocommerce-page .page-title,
.woocommerce-page .entry-title {
  font: 700 normal 85px/1 "Born Strong", Helvetica, Arial, sans-serif;
  color: #ff0000;
  letter-spacing: -0.02em;
  text-align: left;
}
@media screen and (max-width: 1200px) {
  .woocommerce-page .page-title,
  .woocommerce-page .entry-title {
    font-size: 70px;
  }
}
@media screen and (max-width: 1023px) {
  .woocommerce-page .page-title,
  .woocommerce-page .entry-title {
    font-size: 60px;
  }
}
@media screen and (max-width: 767px) {
  .woocommerce-page .page-title,
  .woocommerce-page .entry-title {
    font-size: 44px;
  }
}
.woocommerce-page .product_title {
  font: 400 normal 30px/1.4 "Montserrat", Helvetica, Arial, sans-serif;
  color: #000;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 1200px) {
  .woocommerce-page .product_title {
    font-size: 28px;
  }
}
@media screen and (max-width: 1023px) {
  .woocommerce-page .product_title {
    font-size: 26px;
  }
}
@media screen and (max-width: 767px) {
  .woocommerce-page .product_title {
    font-size: 24px;
  }
}
.woocommerce-page.woocommerce-shop .main, .woocommerce-page.single-product .main {
  width: calc(100% - 80px);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 220px 0 160px;
}
@media screen and (max-width: 767px) {
  .woocommerce-page.woocommerce-shop .main, .woocommerce-page.single-product .main {
    padding-top: 140px;
    width: calc(100% - 60px);
  }
}
.woocommerce-page .button {
  margin-top: 0;
}
.woocommerce-page .place-order .button {
  margin-top: 1em;
}
.woocommerce-page .woocommerce-product-details__short-description {
  margin-bottom: 40px;
}
.woocommerce-page .woocommerce-product-details__short-description p + p,
.woocommerce-page .woocommerce-product-details__short-description p + ul,
.woocommerce-page .woocommerce-product-details__short-description p + ol,
.woocommerce-page .woocommerce-product-details__short-description ul + p,
.woocommerce-page .woocommerce-product-details__short-description ul + ul,
.woocommerce-page .woocommerce-product-details__short-description ul + ol,
.woocommerce-page .woocommerce-product-details__short-description ol + p,
.woocommerce-page .woocommerce-product-details__short-description ol + ul,
.woocommerce-page .woocommerce-product-details__short-description ol + ol {
  margin-top: 1.5em;
}
.woocommerce-page .woocommerce-product-details__short-description p + h1,
.woocommerce-page .woocommerce-product-details__short-description p + h2,
.woocommerce-page .woocommerce-product-details__short-description p + h3,
.woocommerce-page .woocommerce-product-details__short-description ul + h1,
.woocommerce-page .woocommerce-product-details__short-description ul + h2,
.woocommerce-page .woocommerce-product-details__short-description ul + h3,
.woocommerce-page .woocommerce-product-details__short-description ol + h1,
.woocommerce-page .woocommerce-product-details__short-description ol + h2,
.woocommerce-page .woocommerce-product-details__short-description ol + h3 {
  margin-top: 2em;
}
.woocommerce-page .woocommerce-product-details__short-description h2 {
  font: 700 normal 22px/1.4 "Montserrat", Helvetica, Arial, sans-serif;
  color: #000;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 1200px) {
  .woocommerce-page .woocommerce-product-details__short-description h2 {
    font-size: 21px;
  }
}
@media screen and (max-width: 1023px) {
  .woocommerce-page .woocommerce-product-details__short-description h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .woocommerce-page .woocommerce-product-details__short-description h2 {
    font-size: 18px;
  }
}
.woocommerce-page .woocommerce-product-details__short-description h3 {
  font: 700 normal 18px/1.4 "Montserrat", Helvetica, Arial, sans-serif;
  color: #000;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
@media screen and (max-width: 1200px) {
  .woocommerce-page .woocommerce-product-details__short-description h3 {
    font-size: 17px;
  }
}
@media screen and (max-width: 1023px) {
  .woocommerce-page .woocommerce-product-details__short-description h3 {
    font-size: 16px;
  }
}
.woocommerce-page .woocommerce-billing-fields p {
  margin: 10px 0 25px;
}
.woocommerce-page .woocommerce-billing-fields #billing_address_1_field {
  margin-bottom: 0;
}
.woocommerce-page .order-again {
  margin-bottom: 30px;
}

ul.products {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
ul.products li.product {
  width: 33.3333333333%;
  background-color: #fff;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  padding: 0 10px;
}
@media screen and (max-width: 1023px) {
  ul.products li.product {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  ul.products li.product {
    width: 100%;
  }
}
ul.products li.product img {
  height: 400px;
  width: auto;
  max-width: 80%;
  object-fit: contain;
}
@media screen and (max-width: 1500px) {
  ul.products li.product img {
    height: 300px;
  }
}
ul.products li.product .woocommerce-loop-product__title {
  font-weight: 700;
}

.post {
  padding: 220px 0 160px;
}
@media screen and (max-width: 767px) {
  .post {
    padding-top: 140px;
  }
}
.post-content {
  width: calc(100% - 80px);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 767px) {
  .post-content {
    width: calc(100% - 60px);
  }
}
.post-content h1 {
  font: 700 normal 85px/1 "Born Strong", Helvetica, Arial, sans-serif;
  color: #ff0000;
  letter-spacing: -0.02em;
  margin-bottom: 0.5em;
}
@media screen and (max-width: 1200px) {
  .post-content h1 {
    font-size: 70px;
  }
}
@media screen and (max-width: 1023px) {
  .post-content h1 {
    font-size: 60px;
  }
}
@media screen and (max-width: 767px) {
  .post-content h1 {
    font-size: 44px;
  }
}
.post-content h2 {
  font: 700 normal 22px/1.4 "Montserrat", Helvetica, Arial, sans-serif;
  color: #000;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 1200px) {
  .post-content h2 {
    font-size: 21px;
  }
}
@media screen and (max-width: 1023px) {
  .post-content h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .post-content h2 {
    font-size: 18px;
  }
}
.post-content h3 {
  font: 700 normal 18px/1.4 "Montserrat", Helvetica, Arial, sans-serif;
  color: #000;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
@media screen and (max-width: 1200px) {
  .post-content h3 {
    font-size: 17px;
  }
}
@media screen and (max-width: 1023px) {
  .post-content h3 {
    font-size: 16px;
  }
}
.post-content h1 + h1,
.post-content h1 + h2,
.post-content h1 + h3,
.post-content h1 + p,
.post-content h1 + ul,
.post-content h1 + ol,
.post-content h2 + h1,
.post-content h2 + h2,
.post-content h2 + h3,
.post-content h2 + p,
.post-content h2 + ul,
.post-content h2 + ol,
.post-content h3 + h1,
.post-content h3 + h2,
.post-content h3 + h3,
.post-content h3 + p,
.post-content h3 + ul,
.post-content h3 + ol {
  margin-top: 0.5em;
}
.post-content p + p,
.post-content p + ul,
.post-content p + ol,
.post-content ul + p,
.post-content ul + ul,
.post-content ul + ol,
.post-content ol + p,
.post-content ol + ul,
.post-content ol + ol {
  margin-top: 1.5em;
}
.post-content p + h1,
.post-content p + h2,
.post-content p + h3,
.post-content ul + h1,
.post-content ul + h2,
.post-content ul + h3,
.post-content ol + h1,
.post-content ol + h2,
.post-content ol + h3 {
  margin-top: 2em;
}
.post-content li + li {
  margin-top: 0.25em;
}
.post-content img {
  display: block;
  margin: 1.5em 0;
  max-width: 100%;
  height: auto;
}
.post-content blockquote {
  margin: 3em;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .post-content blockquote {
    margin: 1.5em 1em;
  }
}
.post-content a:not(.button) {
  color: currentColor;
  text-decoration: underline;
}

.not-found {
  text-align: center;
}