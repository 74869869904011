//==============================
// Hero
//==============================

.hero {
  height: 100vh;
  min-height: 800px;
  background: #ccc;
  position: relative;

  @include bp(l) {
    min-height: 600px;
    height: 70vw;
  }

  @include bp(m) {
    min-height: 500px;
    // height: 60vw;
  }

  @include bp(s) {
    min-height: 400px;
    height: 100vw;
  }

  img {
    @include centered;
    @include img-cover;
  }
}
