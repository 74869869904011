//==============================
// Fonts
//==============================

@include font('Straight Jacket', 'straightjacketbb_reg.otf', 400, normal);
@include font('Straight Jacket', 'straightjacketbb_bold.otf', 700, normal);

@include font('Badaboom', 'badaboom.ttf', 400, normal);

@include font('Born Strong', 'Born Strong Regular.otf', 400, normal);
@include font('Born Strong', 'Born Strong Black.otf', 700, normal);

@include font('Montserrat', 'Montserrat-Regular.ttf', 400, normal);
@include font('Montserrat', 'Montserrat-Italic.ttf', 400, italic);
@include font('Montserrat', 'Montserrat-Bold.ttf', 700, normal);
@include font('Montserrat', 'Montserrat-BoldItalic.ttf', 700, italic);
@include font('Montserrat', 'Montserrat-ExtraBold.ttf', 800, normal);
@include font('Montserrat', 'Montserrat-ExtraBoldItalic.ttf', 800, italic);
