//==============================
// Social
//==============================

.social {
  display: flex;
  flex-flow: row nowrap;

  li {
    list-style-type: none;

    + li {
      margin-left: 10px;
    }

    a {
      display: block;
      padding: 5px;
      transition: opacity .2s;

      &:hover {
        opacity: .7;
      }
    }

    img {
      display: block;
      height: 40px;
      width: auto;

      @include bp(s) {
        
      }
    }
  }
}