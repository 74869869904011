//==============================
// Buttons
//==============================

.button {
  display: inline-block;
  padding: .8em 2em;
  font: 700 normal 20px/1.2 $font-secondary;
  border: 0;
  transition: background-color .3s, color .3s;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 1.5em;
  background-color: $red;
  color: $white;
  border-radius: 5px;

  @include bp(s) {
    font-size: 16px;
  }

  &:hover {
    background-color: $black;
    color: $white;
  }
}
