//==============================
// Global
//==============================

* {
  box-sizing: border-box;
}

body,
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

::-moz-selection {
  background: $selection;
  text-shadow: none;
}

::selection {
  background: $selection;
  text-shadow: none;
}

// Remove the gap between audio, canvas, iframes,
// images, videos and the bottom of their containers:
// https://github.com/h5bp/html5-boilerplate/issues/440

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

// Remove default fieldset styles.
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

// Allow only vertical resizing of textareas.

textarea {
  resize: vertical;
}

[tabindex="0"] {
  outline: 0;
}

.main {
  min-height: 800px;
  @include clearfix;
}

img {
  border-radius: 0;
}
