//==============================
// Variables
//==============================


// Colors
//------------------------------

$selection: #b3d4fc;

$white: #fff;
$black: #000;
$charcoal: #333;
$red: #ff0000;

$font-primary: 'Montserrat', Helvetica, Arial, sans-serif;
$font-secondary: 'Born Strong', Helvetica, Arial, sans-serif;

$header-transition-time: .4s;

$grid-gutter: 40px;
