//==============================
// Home
//==============================

.home {

  &-section {
    padding: 140px 0;

    @include bp(m) {
      padding: 100px 0;
    }

    @include bp(s) {
      padding: 60px 0;
    }

    &-header {
      text-align: center;
      padding: 0 30px 40px;

      @include bp(s) {
        padding-bottom: 20px;
      }
    }
  }

  &-split {
    width: calc(100% - 80px);
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    @include bp(m) {
      width: calc(100% - 60px);
    }

    &-content {
      padding: 40px 100px;
      width: 60%;

      @include bp(l) {
        padding: 60px;
      }

      @include bp(m) {
        padding: 40px;
        width: 100%;
        max-width: 600px;
      }

      @include bp(s) {
        padding: 40px 0 0;
      }

      iframe {
        margin-top: 20px;
      }
    }

    &-img {
      width: 40%;
      // perspective: 1000px;

      @include bp(m) {
        width: 100%;
        max-width: 600px;
        order: -1;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        // transform: rotateY(20deg);

        box-shadow: 0 15px 15px -5px rgba(0,0,0,.2);
      }
    }
  }

  &-video {
    background: #ff0000 url('assets/images/home/video-bg.svg') center center / 100% 100% no-repeat;

    .eyebrow, .h1 {
      color: $white;
    }
    
    &-inner {
      width: calc(100% - 120px);
      max-width: 1200px;
      margin: 0 auto;
      position: relative;

      @include bp(s) {
        width: calc(100% - 60px);
      }
    }

    &-smashfuck {
      background: #493c06 url('assets/images/home/smashfuck-video-bg.svg') center center / 100% 100% no-repeat;
    }

    &-warning {
      @include fillcontainer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url('assets/videos/smashfuck-poster.jpg') center center / cover no-repeat;
      z-index: 1;

      &-inner {
        background: transparentize($black, .3);
        border-radius: 5px;
        padding: 40px;
        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        @include bp(s) {
          border-radius: 0;
          width: 100%;
          height: 100%;
        }

        .button {
          margin-top: 1em;
        }
      }
    }

    &.home-hero-video {
      background: #1b539e url('assets/images/home/hero-video-bg.svg') center center / 100% 100% no-repeat;
    }
  }

  &-music {

    .social {
      margin-top: 10px;
    }

    &-embed {
      width: 80%;
      margin-top: 100px;

      @include bp(m) {
        width: 100%;
        max-width: 600px;
        margin-top: 40px;
      }

      @include bp(s) {
        
      }
    }
  }

  &-band {
    // background-color: #0000b9;
    background: #1ba9e3 url('assets/images/home/band-bg.svg') center center / 100% 100% no-repeat;

    .h1 {
      color: $white;
    }

    &-items {
      display: flex;
      flex-flow: row wrap;
      width: calc(100% - 80px);
      max-width: 1400px;
      margin: 0 auto;

      @include bp(l) {
        width: 100%;
      }

      @include bp(m) {
        width: calc(100% - 60px);
      }

      @include bp(s) {
        width: calc(100% - 40px);
        max-width: 500px;
      }
    }

    &-item {
      display: flex !important;
      flex-flow: row wrap;
      align-items: center;

      &:nth-child(5n) {
        margin-right: 0;
      }

      &-img {
        width: 45%;
        position: relative;
        overflow: hidden;

        @include bp(s) {
          width: 100%;
          height: 100vw;
          max-height: 500px;
        }

        img {
          display: block;
          width: 100%;

          @include bp(s) {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
          }
        }
      }

      &-content {
        width: 55%;
        padding: 100px;

        @include bp(l) {
          padding: 60px;
        }

        @include bp(m) {
          padding: 40px;
        }

        @include bp(s) {
          padding: 30px;
        }

        @include bp(s) {
          width: 100%;
        }
      }
    }

    .slick {

      &-arrow {
        overflow: hidden;
        text-indent: -9999px;
        @include input-reset;
        background-color: transparent;
        position: relative;
        width: 80px;
        padding: 0;
        margin: 0;
        cursor: pointer;

        @include bp(m) {
          @include hide;
        }

        &:before {
          content: '';
          position: absolute;
          background-color: $white;
          width: 40px;
          height: 60px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 3px;

          @include bp(s) {
            width: 30px;
            height: 30px;
          }
        }
      }

      &-prev {
        order: 1;

        &:before {
          background: $white url('assets/images/global/arrow-left.svg') 45% center / 30% auto no-repeat;
        }
      }

      &-next {
        order: 3;

        &:before {
          background: $white url('assets/images/global/arrow-right.svg') 55% center / 30% auto no-repeat;
        }
      }

      &-dots {
        order: 2;
      }

      &-list {
        order: 2;
        width: calc(100% - 160px);
        // max-width: 700px;
        margin: 0 auto;
        background-color: #fff;
        box-shadow: 0 15px 15px -5px rgba(0,0,0,.2);

        @include bp(m) {
          width: 100%;
        }
      }

      &-dots {
        width: 100%;
        margin: 20px 0 0 0;
        padding: 10px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        z-index: 1;
        order: 4;

        li {
          width: 7px;
          height: 7px;
          background: none;
          list-style-type: none;
          margin: 0 3px;
          border-radius: 5px;
          background-color: $white;
          opacity: .5;
          cursor: pointer;
          transition: opacity .2s;

          &.slick-active {
            // background: #126b7f;
            opacity: 1;
          }

          button {
            display: none;
          }
        }
      }
    }
  }

  &-shop {

    .products {
      width: calc(100% - 30px);
      max-width: 1600px;
      margin: 0 auto;

      // @include bp(s) {
      //   width: calc(100% - 30px);
      // }
    }
  }
}


