//==============================
// Footer
//==============================

.footer {
  background-color: #f0f0f0;
  padding: 50px 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-family: $font-primary;

  &-logo {
    width: 200px;
    margin-bottom: 20px;

    @include bp(s) {
      width: 150px;
    }
  }

  &-sauce {
    // @include p2;
    text-align: center;
    // color: $satan;
    margin-top: 40px;

    a {
      color: currentColor;
      text-decoration: none;

      &:hover,
      &:focus {
        border-bottom: currentColor solid 1px;
      }
    }
  }

  .header-nav-utility {
    margin-left: 0;

    svg {
      fill: $red;
    }
  }
}
