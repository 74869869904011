/*!
Theme Name: Punk Rock Time Machine
Author: Robert Pessagno
Version: 1.0
Template: storefront
Description: This is a custom child theme for WooThemes StoreFront theme
*/

//==============================
// Main
//==============================


// Global
//------------------------------
@import 'global/mixins';
@import 'global/fonts';
@import 'global/variables';
@import 'global/normalize';
@import 'global/global';
@import 'global/typography';


// Libraries
//------------------------------
@import 'lib/slick';


// Components
//------------------------------
@import 'components/forms';
@import 'components/buttons';
@import 'components/header';
@import 'components/footer';
@import 'components/hero';
@import 'components/social';


// Templates
//------------------------------
@import 'templates/home';
@import 'templates/woocommerce';
@import 'templates/post';
@import 'templates/not-found';

// Print
//------------------------------
@import 'global/print';
