//==============================
// Header
//==============================

.header {
  position: fixed;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  // width: 100%;
  width: calc(100% - 40px);
  // left: 0;
  // top: 0;
  left: 20px;
  top: 20px;
  border-radius: 5px;
  z-index: 10;
  height: 90px;
  padding: 0 30px;
  background-color: $red;
  box-shadow: 0 3px 7px rgba(0,0,0,.2);
  transition: width $header-transition-time, background-color $header-transition-time, transform $header-transition-time, box-shadow $header-transition-time;

  @include bp(m) {
    height: 70px;
    padding: 0 20px 0 15px;
    width: calc(100% - 20px);
    left: 10px;
    top: 20px;
  }

  // &:after {
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   border-bottom: darken($white, 10%) solid 1px;
  // }

  &.open {

    .header-logo {

      .aaron-logo-white {
        fill: $black;
      }
    }

    #menu-main-1 {

      .menu-item {
        color: $black;

        &:after {
          border-color: $black;
        }
      }
    }

    .header-shop-item {
      color: $black;

      svg {
        fill: $red;
      }
    }
  }

  &-logo {
    position: relative;
    z-index: 101;

    @include bp(m) {
      // top: -2px;
    }

    img {
      display: block;
      height: 125px;
      width: auto;

      @include bp(m) {
        height: 85px;
      }

      .aaron-logo-white {
        transition: fill $header-transition-time;
      }
    }
  }

  &-nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    // margin-left: 60px;
    flex-grow: 1;
    height: 100%;

    @include bp(l) {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      max-width: 500px;
      height: 100%;
      z-index: 100;
      margin-left: 0;
      padding: 60px 0 0 0;
      background-color: $red;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      transform: translateX(100%);
      visibility: hidden;
      transition: opacity .5s, visibility .5s, transform .5s;
    }

    @include bp(s) {
      max-width: none;
    }

    &.open {

      @include bp(l) {
        visibility: visible;
        transform: translate(0);
      }
    }

    &-logo {
      // margin-right: 5px;
      margin-right: auto;

      a {
        display: block;

        img {
          width: 100px;
        }
      }
    }

    &-menu {
      height: 100%;
      margin-left: auto;
      display: flex;
      flex-flow: row nowrap;

      @include bp(l) {
        flex-flow: column nowrap;
        align-items: center;
        height: auto;
        width: 100%;
        height: auto;
        margin-top: auto;
        padding-bottom: 40px;
      }

      li {
        font: 700 normal 20px/1 $font-secondary;
        list-style-type: none;
        position: relative;
        color: $white;

        @include bp(s) {
          font-size: 18px;
        }

        a {
          height: 100%;
          padding: 0 1em;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          position: relative;
          text-decoration: none;
          color: inherit;
          transition: color .2s;
          position: relative;
          z-index: 2;
          transition: text-shadow .2s;

          @include bp(l) {
            padding: .4em;
            font-size: 30px;
          }

          // &:after {
          //   content: '';
          //   position: absolute;
          //   left: 0;
          //   bottom: 0;
          //   height: 0;
          //   width: 100%;
          //   background-color: $red;
          //   z-index: 1;
          //   transition: height .1s;

          //   @include bp(l) {
          //     @include hide;
          //   }
          // }

          &:hover,
          &[aria-current="page"] {
            // padding-bottom: 7px;
            text-shadow: 2px 2px 0 darken($red, 30%);

            // &:after {
            //   height: 3px;
            // }
          }
        }
      }
    }

    // &-contact {
    //   margin: 0 20px 0 auto;

    //   @include bp(l) {
    //     width: 100%;
    //     margin: 20px 0;
    //     text-align: center;
    //   }

    //   a.button {
    //     margin: 0;
    //   }
    // }

    &-utility {
      display: flex;
      flex-flow: row nowrap;
      margin-left: 30px;

      @include bp(m) {
        margin-left: 0;
      }

      li {
        list-style-type: none;

        a {
          display: block;
          padding: 10px;
          transition: opacity .2s;

          &:hover {
            opacity: .7;
          }
        }

        svg {
          display: block;
          height: 25px;
          width: auto;
          fill: $white;
        }
      }

      + .header-nav-utility {
        border-left: 1px solid $white;
        margin-left: 20px;
        padding-left: 20px;
      }
    }

    &-button {
      @include input-reset;
      width: 50px;
      height: 50px;
      padding: 0;
      position: relative;
      background-color: transparent !important;
      display: none;
      margin-left: auto;
      order: 1;
      z-index: 101;
      margin-left: auto;

      @include bp(l) {
        display: block;
      }

      span {
        display: block;
        width: 30px;
        height: 2px;
        background-color: $white;
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 1;
        transition: transform $header-transition-time, opacity $header-transition-time, width $header-transition-time, background-color $header-transition-time;

        &.top {
          transform: translate(-50%, -7px) rotate(0deg);
          transform-origin: left top;
        }

        &.middle {
          transform: translate(-50%, 0) rotate(0deg);
        }

        &.bottom {
          transform-origin: left bottom;
          transform: translate(-50%, 7px) rotate(0deg);
        }
      }

      &.open {

        span {

          &.top {
            transform: translate(-35%, -8px) rotate(45deg);
          }

          &.middle {
            opacity: 0;
          }

          &.bottom {
            transform: translate(-35%, 12px) rotate(-45deg);
          }
        }
      }
    }
  }
}
